import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { RopeDimensionService } from 'services/rope-dimension-service';

@autoinject
export class RopeDimensionList {
  private tableData: Array<Models.RopeDimension>;
  private activeTableRow: number;
  private ready: boolean = false;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private ropeDimensionService: RopeDimensionService,
    private router: Router
  ) {}

  private activate(params) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  private attached(params) {
    this.eventAggregator.subscribe('ropeDimensionListReset', (value) => {
      this.activeTableRow = null;
      return this.updateView();
    });
    this.updateView();
  }

  private updateView() {
    this.ropeDimensionService
      .getAll('?$orderby=Name')
      .then((res) => {
        this.ready = true;
        this.tableData = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoDetailView(id) {
    this.activeTableRow = id;
    this.router.navigateToRoute('rope-dimension-detail', { Id: id });
  }
}
