import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { RopeDimensionService } from "services/rope-dimension-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class RopeDimensionNew {
  private ropedimension: Models.RopeDimension = new Models.RopeDimension();

  constructor(
    private ropeDimensionService: RopeDimensionService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createRopeDimension() {
    this.ropeDimensionService
      .post(this.ropedimension)
      .then(res => {
        this.eventAggregator.publish("ropeDimensionListReset", 1);
        this.toastService.showSuccess("ropedimension.created");
        this.router.navigateToRoute("rope-dimension-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
