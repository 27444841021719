import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { RopeDimensionService } from "services/rope-dimension-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class ropeDimensionDetail {
  private ropedimension: Models.RopeDimension;

  constructor(
    private ropeDimensionService: RopeDimensionService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.ropeDimensionService
      .get(params.Id)
      .then(ropeDimension => {
        this.ropedimension = ropeDimension;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateRopeDimension() {
    this.ropeDimensionService
      .put(this.ropedimension, this.ropedimension.Id)
      .then(res => {
        this.eventAggregator.publish("ropeDimensionListReset", 1);
        this.toastService.showSuccess("ropedimension.updated");
        this.router.navigateToRoute("rope-dimension-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteRopeDimension() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.ropeDimensionService
          .delete(this.ropedimension.Id)
          .then(res => {
            this.eventAggregator.publish("ropeDimensionListReset", 1);
            this.toastService.showSuccess("ropedimension.deleted");
            this.router.navigateToRoute("rope-dimension-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      ); 
  }
  
  gotoParentView(){
    this.eventAggregator.publish("ropeDimensionListReset", 1);
    this.router.navigateToRoute("rope-dimension-list");   
  }
}
