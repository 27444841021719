import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, containerless, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { ValidationController, Validator } from 'aurelia-validation';
import { StandardValidationBuilder } from 'lib/validation';
import { NetInfo, NetShape, RopePlacement } from 'models';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { RopeDimensionService } from 'services/rope-dimension-service';
import { RopeHardnessService } from 'services/rope-hardness-service';
import { RopePlacementService } from 'services/rope-placement-service';
import { RopeService } from 'services/rope-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ToastService } from 'services/toast-service';

const logger: Logger = LogManager.getLogger('rope-form-inline-edit');

@containerless
@autoinject
export class RopeFormInlineEdit {
  @bindable protected netId: number;
  @bindable protected isMorenotNet: boolean = true;
  @bindable private rope: Models.Rope = new Models.Rope();
  @bindable private minimumBreakingStrength: number;

  protected netInfo: NetInfo;

  private currentRopeType: Models.RopeType;
  protected actualBreakingStrengthMissing: boolean = false;
  protected isExtraRope: boolean = false;

  protected ropePlacement: Models.RopePlacement[] = [];

  constructor(
    private ropeService: RopeService,
    private ropeTypeService: RopeTypeService,
    private netSerivce: NetService,
    private ropeDimensionService: RopeDimensionService,
    private ropeHardnessService: RopeHardnessService,
    private ropePlacementService: RopePlacementService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    protected validator: Validator,
    protected validationController: ValidationController,
    protected standardValidation: StandardValidationBuilder
  ) {}

  attached() {
    if (!this.rope.MinBreakingStrength) {
      this.rope.MinBreakingStrength = this.minimumBreakingStrength;
    }

    void this.setup();
  }

  async setup() {
    await this.getNetInfo();
    await Promise.allSettled([this.getRopePlacements(), this.getRopeTypesUsedNet()]);
    this.addValidation();
  }

  async getNetInfo() {
    try {
      this.netInfo = await this.netSerivce.getNetDetails(this.netId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async validate() {
    const result = await this.validationController.validate();
    return result.valid;
  }

  private onSaved() {
    this.eventAggregator.publish('ropeListReset', 1);
    this.eventAggregator.publish('ropeFormNewClose', 1);
    this.eventAggregator.publish('ropeFormEditClose', 1);

    this.rope = new Models.Rope();
    this.actualBreakingStrengthMissing = false;
    this.isExtraRope = false;
    this.currentRopeType = null;
  }

  async createRope() {
    try {
      if (!(await this.validate())) {
        return;
      }
      this.rope.NetId = this.netId;
      await this.ropeService.post(this.rope);
      this.toastService.showSuccess('rope.created');
      this.onSaved();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async updateRope() {
    try {
      if (!(await this.validate())) {
        return;
      }
      this.rope.RopePlacement = null;
      this.rope.RopeHardness = null;
      this.rope.RopeDimension = null;
      this.rope.RopeType = null;
      if (!this.rope.Amount) this.rope.Amount = 0;
      await this.ropeService.put(this.rope, this.rope.Id);
      this.toastService.showSuccess('rope.updated');
      this.onSaved();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected addValidation() {
    if (this.isMorenotNet) return;

    this.standardValidation
      .with(this.rope)
      .required('RopeTypeId')
      .required('RopeDimensionId')
      .min('Amount', 0, (x) => {
        return Boolean(x.Amount);
      })
      .max('Amount', 100)
      .min('MinBreakingStrength', 50, (x) => {
        return Boolean(x.MinBreakingStrength);
      })
      .max('MinBreakingStrength', 20000)
      .min('ActualBreakingStrength', 50, (x) => {
        return Boolean(x.ActualBreakingStrength);
      })
      .max('ActualBreakingStrength', 20000)
      .done();
  }

  protected async getRopePlacements() {
    try {
      let placements = await this.ropePlacementService.getAllCached();
      const isConedNet = +this.netInfo.NetShapeId === NetShape.CONED_NET;
      if (!isConedNet) {
        placements = placements.filter((x) => x.Id != RopePlacement.CROSS_ROPE_INNER_BOTTOM);
      }
      this.ropePlacement = placements;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected ropePlacementChanged(event: CustomEvent) {
    this.rope.RopePlacementId = event.detail.value;
    if (!this.rope.RopePlacementId) return;
    this.isExtraRope = +this.rope.RopePlacementId === RopePlacement.EXTRA_ROPE;
  }

  protected async getRopeTypes() {
    try {
      const ropeTypes = await this.ropeTypeService.getAll();

      return ropeTypes.filter((x) => x.NavisionProductId || x.DeltaProductId);
    } catch (err) {
      this.errorService.handleError(err);
    }
    return [];
  }

  protected async getRopeTypesUsedNet() {
    try {
      const ropeTypes = await this.ropeTypeService.getAll();
      let noneMorenotProducts = ropeTypes.filter((x) => !x.NavisionProductId && !x.DeltaProductId);
      // in case one of the morenot products is used in an existing used net, get those but mark them as deleted
      let morenotProducts = ropeTypes.filter((x) => x.NavisionProductId || x.DeltaProductId);
      morenotProducts.forEach((x) => (x.IsDeleted = true));
      noneMorenotProducts = noneMorenotProducts.concat(morenotProducts);

      return noneMorenotProducts;
    } catch (error) {
      this.errorService.handleError(error);
    }
    return [];
  }

  protected async ropeTypeChanged(event: CustomEvent) {
    if (!event.detail.value || this.rope?.RopeTypeId === event.detail.value) {
      return;
    }

    this.rope.RopeTypeId = event.detail.value;
    try {
      const ropeTypes = await this.ropeTypeService.get(this.rope.RopeTypeId + '?$expand=NavisionProduct');
      this.currentRopeType = ropeTypes;
      this.rope.RopeHardnessId = this.currentRopeType.RopeHardnessId;
      this.rope.RopeDimensionId = this.currentRopeType.RopeDimensionId;
      if (this.currentRopeType.NavisionProduct) {
        this.rope.ActualBreakingStrength = this.currentRopeType.NavisionProduct.MinBreakingStrength;
        if (!this.currentRopeType.NavisionProduct.MinBreakingStrength) {
          this.toastService.showError('rope.actualBreakingStrengthMissing');
          this.actualBreakingStrengthMissing = true;
        } else {
          this.actualBreakingStrengthMissing = false;
        }
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getRopeType() {
    if (!this.rope.RopeTypeId || !!this.rope.ActualBreakingStrength) {
      return;
    }

    try {
      const ropeType = await this.ropeTypeService.get(this.rope.RopeTypeId + '?$expand=NavisionProduct');
      this.currentRopeType = ropeType;
      if (this.currentRopeType.NavisionProduct) {
        if (!this.currentRopeType.NavisionProduct.MinBreakingStrength) {
          this.toastService.showError('rope.actualBreakingStrengthMissing');
          this.actualBreakingStrengthMissing = true;
        } else {
          this.actualBreakingStrengthMissing = false;
        }
      }
    } catch (error) {
      // not much to do here - the ropetype is probably deleted, but this is only needed
      // for setting the ActualBreakingStrength if it is not already set. Just log it to console
      logger.debug('Error getting ropetype to set ActualBreakingStrength', error);
    }
  }

  protected async deleteRope() {
    try {
      await this.ropeService.delete(this.rope.Id);
      this.eventAggregator.publish('ropeFormEditClose', 1);
      this.eventAggregator.publish('ropeListReset', 1);
      this.toastService.showSuccess('rope.deleted');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected cancelEdit() {
    this.eventAggregator.publish('ropeFormEditClose', 1);
    this.eventAggregator.publish('ropeFormNewClose', 1);
    this.eventAggregator.publish('ropeListReset', 1);
  }
}
