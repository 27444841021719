import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { RingSinkerFloaterConnectionTypeService } from 'services/ring-sinker-floater-connection-type-service';

@autoinject
export class RingSinkerFloaterConnectionTypeNew {
  private ringSinkerFloaterConnectionType: Models.RingSinkerFloaterConnectionType = new Models.RingSinkerFloaterConnectionType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private ringSinkerFloaterConnectionTypeService: RingSinkerFloaterConnectionTypeService
  ) {}

  private createRingSinkerFloaterConnectionType() {
    this.ringSinkerFloaterConnectionTypeService
      .post(this.ringSinkerFloaterConnectionType)
      .then((res) => {
        this.eventAggregator.publish('ringSinkerFloaterConnectionTypeListReset', 1);
        this.toastService.showSuccess('ringSinkerFloaterConnectionType.created');
        this.router.navigateToRoute('ring-sinker-floater-connection-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
