import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { RingSinkerNetConnectionTypeService } from 'services/ring-sinker-net-connection-type-service';

@autoinject
export class RingSinkerNetConnectionTypeDetail {
  private ringSinkerNetConnectionType: Models.RingSinkerNetConnectionType;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private ringSinkerNetConnectionTypeService: RingSinkerNetConnectionTypeService
  ) {}

  private activate(params) {
    this.ringSinkerNetConnectionTypeService
      .get(params.Id)
      .then( (res) => {
        this.ringSinkerNetConnectionType = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateRingSinkerNetConnectionType() {
    this.ringSinkerNetConnectionTypeService
      .put(this.ringSinkerNetConnectionType, this.ringSinkerNetConnectionType.Id)
      .then((res) => {
        this.eventAggregator.publish('ringSinkerNetConnectionTypeListReset', 1);
        this.toastService.showSuccess('ringSinkerNetConnectionType.updated');
        this.router.navigateToRoute('ring-sinker-net-connection-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteRingSinkerNetConnectionType() {
    this.ringSinkerNetConnectionTypeService
      .delete(this.ringSinkerNetConnectionType.Id)
      .then((res) => {
        this.eventAggregator.publish('ringSinkerNetConnectionTypeListReset', 1);
        this.toastService.showSuccess('ringSinkerNetConnectionType.deleted');
        this.router.navigateToRoute('ring-sinker-net-connection-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoParentView() {
    this.eventAggregator.publish('ringSinkerNetConnectionTypeListReset', 1);
    this.router.navigateToRoute('ring-sinker-net-connection-type-list');
  }
}
