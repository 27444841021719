import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { RingSinkerNetConnectionTypeService } from 'services/ring-sinker-net-connection-type-service';

@autoinject
export class RingSinkerNetConnectionTypeNew {
  private ringSinkerNetConnectionType: Models.RingSinkerNetConnectionType = new Models.RingSinkerNetConnectionType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private ringSinkerNetConnectionTypeService: RingSinkerNetConnectionTypeService
  ) {}

  private createRingSinkerNetConnectionType() {
    this.ringSinkerNetConnectionTypeService
      .post(this.ringSinkerNetConnectionType)
      .then((res) => {
        this.eventAggregator.publish('ringSinkerNetConnectionTypeListReset', 1);
        this.toastService.showSuccess('ringSinkerNetConnectionType.created');
        this.router.navigateToRoute('ring-sinker-net-connection-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
