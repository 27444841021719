import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class RopeTypeDetail {
  private ropetype: Models.RopeType;

  constructor(
    private ropeTypeService: RopeTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.ropeTypeService
      .get(params.Id)
      .then((ropeType) => {
        this.ropetype = ropeType;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateRopeType() {
    this.ropeTypeService
      .put(this.ropetype, this.ropetype.Id)
      .then((res) => {
        this.eventAggregator.publish('ropeTypeListReset', 1);
        this.toastService.showSuccess('ropetype.updated');
        this.router.navigateToRoute('rope-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteRopeType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.ropeTypeService
          .delete(this.ropetype.Id)
          .then((res) => {
            this.eventAggregator.publish('ropeTypeListReset', 1);
            this.toastService.showSuccess('ropetype.deleted');
            this.router.navigateToRoute('rope-type-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );   
  }
  
  private gotoParentView() {
    this.eventAggregator.publish('ropeTypeListReset', 1);
    this.router.navigateToRoute('rope-type-list');
  }
}
