import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { RopePlacementService } from "services/rope-placement-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class ropePlacementDetail {
  private ropeplacement: Models.RopePlacement;

  constructor(
    private ropePlacementService: RopePlacementService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.ropePlacementService
      .get(params.Id)
      .then(ropePlacement => {
        this.ropeplacement = ropePlacement;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateRopePlacement() {
    this.ropePlacementService
      .put(this.ropeplacement, this.ropeplacement.Id)
      .then(res => {
        this.eventAggregator.publish("ropePlacementListReset", 1);
        this.toastService.showSuccess("ropeplacement.updated");
        this.router.navigateToRoute("rope-placement-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteRopePlacement() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.ropePlacementService
          .delete(this.ropeplacement.Id)
          .then(res => {
            this.eventAggregator.publish("ropePlacementListReset", 1);
            this.toastService.showSuccess("ropeplacement.deleted");
            this.router.navigateToRoute("rope-placement-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      ); 
  }
  gotoParentView(){
    this.eventAggregator.publish("ropePlacementListReset", 1);
    this.router.navigateToRoute("rope-placement-list");   
  }
}
