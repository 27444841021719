import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, bindingMode, containerless } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { RopeDimensionService } from 'services/rope-dimension-service';
import { RopeHardnessService } from 'services/rope-hardness-service';
import { RopePlacementService } from 'services/rope-placement-service';
import { RopeService } from 'services/rope-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class RopeFormInlineNew {
  @bindable private netId: number;
  @bindable private minimumBreakingStrength: number;
  @bindable protected isMorenotNet = true;

  protected rope: Models.Rope = new Models.Rope();
  protected currentRopeType: Models.RopeType;
  protected actualBreakingStrengthMissing = false;

  constructor(
    private ropeService: RopeService,
    private ropeTypeService: RopeTypeService,
    private ropePlacementService: RopePlacementService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private ropeHardnessService: RopeHardnessService,
    private ropeDimensionService: RopeDimensionService
  ) { }

  protected attached() {
    this.rope.MinBreakingStrength = this.minimumBreakingStrength;
  }

  async createRope() {
    try {
      this.rope.NetId = this.netId;
      await this.ropeService.post(this.rope);
      this.eventAggregator.publish('ropeListReset', 1);
      this.eventAggregator.publish('ropeFormNewClose', 1);
      this.toastService.showSuccess('rope.created');
      this.rope = new Models.Rope();
      this.actualBreakingStrengthMissing = false;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async getRopeTypes() {
    try {
      const res = await this.ropeTypeService.getAllCached();
      return res.filter((x) => x.NavisionProductId || x.DeltaProductId);
    } catch (error) {
      this.errorService.handleError(error);
    }
    return [];
  }

  async getRopeTypesUsedNet() {
    try {
      const res = await this.ropeTypeService.getAllCached();
      return res.filter((x) => (!x.NavisionProductId && !x.DeltaProductId) || !x.IsDeleted);
    } catch (error) {
      this.errorService.handleError(error);
    }
    return [];
  }

  ropeTypeChanged(event: CustomEvent) {
    if (!event.detail.value || this.rope?.RopeTypeId === event.detail.value) {
      return;
    }
    this.rope.RopeTypeId = event.detail.value;
    this.ropeTypeService
      .get(this.rope.RopeTypeId + '?$expand=NavisionProduct')
      .then((res) => {
        this.currentRopeType = res;
        if (this.currentRopeType.NavisionProduct) {
          this.rope.ActualBreakingStrength = this.currentRopeType.NavisionProduct.MinBreakingStrength;
          if (!this.currentRopeType.NavisionProduct.MinBreakingStrength) {
            this.toastService.showError('rope.actualBreakingStrengthMissing');
            this.actualBreakingStrengthMissing = true;
          } else {
            this.actualBreakingStrengthMissing = false;
          }
        }
        this.rope.RopeHardnessId = this.currentRopeType.RopeHardnessId;
        this.rope.RopeDimensionId = this.currentRopeType.RopeDimensionId;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  cancel() {
    this.eventAggregator.publish('ropeFormNewClose', 1);
  }

  setRopeType(event: CustomEvent) {
    this.rope.RopeTypeId = event.detail.value;
  }

  setRopePlacement(event: CustomEvent) {
    this.rope.RopePlacementId = event.detail.value;
  }
}
