import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { RingSinkerFloaterConnectionTypeService } from 'services/ring-sinker-floater-connection-type-service';

@autoinject
export class RingSinkerFloaterConnectionTypeDetail {
  private ringSinkerFloaterConnectionType: Models.RingSinkerFloaterConnectionType;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private ringSinkerFloaterConnectionTypeService: RingSinkerFloaterConnectionTypeService
  ) {}

  private activate(params) {
    this.ringSinkerFloaterConnectionTypeService
      .get(params.Id)
      .then( (res) => {
        this.ringSinkerFloaterConnectionType = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateRingSinkerFloaterConnectionType() {
    this.ringSinkerFloaterConnectionTypeService
      .put(this.ringSinkerFloaterConnectionType, this.ringSinkerFloaterConnectionType.Id)
      .then((res) => {
        this.eventAggregator.publish('ringSinkerFloaterConnectionTypeListReset', 1);
        this.toastService.showSuccess('ringSinkerFloaterConnectionType.updated');
        this.router.navigateToRoute('ring-sinker-floater-connection-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteRingSinkerFloaterConnectionType() {
    this.ringSinkerFloaterConnectionTypeService
      .delete(this.ringSinkerFloaterConnectionType.Id)
      .then((res) => {
        this.eventAggregator.publish('ringSinkerFloaterConnectionTypeListReset', 1);
        this.toastService.showSuccess('ringSinkerFloaterConnectionType.deleted');
        this.router.navigateToRoute('ring-sinker-floater-connection-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoParentView() {
    this.eventAggregator.publish('ringSinkerFloaterConnectionTypeListReset', 1);
    this.router.navigateToRoute('ring-sinker-floater-connection-type-list');
  }
}
