import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class RopeTypeNew {
  private ropetype: Models.RopeType = new Models.RopeType();

  constructor(
    private ropeTypeService: RopeTypeService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  private createRopeType() {
    this.ropeTypeService
      .post(this.ropetype)
      .then((res) => {
        this.eventAggregator.publish('ropeTypeListReset', 1);
        this.toastService.showSuccess('ropetype.created');
        this.router.navigateToRoute('rope-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
