import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { RopePlacementService } from "services/rope-placement-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class RopePlacementNew {
  private ropeplacement: Models.RopePlacement = new Models.RopePlacement();

  constructor(
    private ropePlacementService: RopePlacementService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createRopePlacement() {
    this.ropePlacementService
      .post(this.ropeplacement)
      .then(res => {
        this.eventAggregator.publish("ropePlacementListReset", 1);
        this.toastService.showSuccess("ropeplacement.created");
        this.router.navigateToRoute("rope-placement-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
