import { ErrorService } from './../../services/error-service';
import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ProductService } from 'services/product-service';
import { RopeDimensionService } from 'services/rope-dimension-service';
import { RopeHardnessService } from 'services/rope-hardness-service';

@autoinject
export class RopeTypeForm {
  @bindable private ropetype: Models.RopeType;

  private products: Array<Models.Product>;
  private navisionProducts: Promise<Array<Models.Product>>;
  private deltaProducts: Promise<Array<Models.Product>>;

  // dummy values to simplify radiobutton binding for true/false values
  private trueVal: boolean = true;
  private falseVal: boolean = false;

  constructor(
    private ropeDimensionService: RopeDimensionService,
    private ropeHardnessService: RopeHardnessService,
    private productService: ProductService,
    private errorService: ErrorService
  ) {  }
  
  private setNavisionProductId(event) {
    this.ropetype.NavisionProductId = event.detail.value;
    this.setProductName(event.detail.value);
  }

  private setDeltaProductId(event) {
    this.ropetype.DeltaProductId = event.detail.value;
    this.setProductName(event.detail.value);
  }

  private getProduct(id): Promise<any> {
    return new Promise((resolve, reject) => { this.productService.get(id)
      .then((res) => {        
        resolve(res);
      })
      .catch((err) => this.errorService.handleError(err));
    });
  }

  private setProductName(productId: string) {
    if (!this.ropetype.Name || this.ropetype.Name === '') {
      if (productId) {
        this.getProduct(productId).then((res) => {
          this.ropetype.Name = res.Name;
        });
      }
    }
  }
}
